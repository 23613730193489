import Vue from 'vue'
import store from '../store'
import RecordRTC from './RecordRTC.min'
import { saveAs } from 'file-saver'
import i18n from './i18n'
import io from 'socket.io-client'
import SoundMeter from './soundmeter'
import { RnnoiseWorkletNode, loadRnnoise } from '@sapphi-red/web-noise-suppressor'
import { tsvb } from 'effects-sdk'
import * as Signicat from './signicat'
const rnnoiseWasmPath = '/js/rnnoise.wasm'
const rnnoiseWasmSimdPath = '/js/rnnoise_simd.wasm'
const rnnoiseWorkletPath = '/js/workletProcessor.js'
const EventBus = new Vue()

const Webrtc = class Webrtc {
  EventBus = EventBus
  connection = null
  status = 'offline'
  forcedLogout = false
  localStream = null
  localStreamScreen = null
  roomId = null
  pageUnloaded = false
  iceDisconnected = false
  soundMeter = null
  soundMeterInterval = null
  p2p = true
  showDeviceSettingsPane = false
  uploadBumicomRecording = false
  axiosInstance = null
  noiseSuppressionContext = null
  effectsSDK = null
  replaceStreamWaitForPCInterval = null
  isVideoEffectRunning = false
  backgroundImage = null
  constructor () {
    this.initializeEffectsSDK()
    window.addEventListener('wsd-licode-ice-disconnected', (connectionStateEvent) => {
      if (connectionStateEvent.detail && connectionStateEvent.detail.state === 'disconnected') {
        this.iceDisconnected = true
        // this.EventBus.$emit('onRemoteAgentDisconnect', userId)
      } else if (connectionStateEvent.detail && connectionStateEvent.detail.state === 'connected') {
        this.iceDisconnected = false
        // this.EventBus.$emit('onRemoteAgentDisconnect', userId)
      }
    })

    window.addEventListener('beforeunload', (event) => {
      this.pageUnloaded = true
      this.disconnect(false)
    })
    window.addEventListener('pagehide', (event) => {
      this.pageUnloaded = true
      this.disconnect(false)
    })

    setInterval(async () => {
      if (this.localStream !== null && this.activeVideoDevice !== null) {
        let deviceFound = false
        let devices = await navigator.mediaDevices.enumerateDevices()
        this.videoDevices = []
        this.audioDevices = []
        this.outputDevices = []
        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            this.videoDevices.push({
              'label': device.label,
              'deviceId': device.deviceId
            })
          } else if (device.kind === 'audioinput') {
            this.audioDevices.push({
              'label': device.label,
              'deviceId': device.deviceId
            })
          } else if (device.kind === 'audiooutput') {
            this.outputDevices.push({
              'label': device.label,
              'deviceId': device.deviceId
            })
          }
          if (device.deviceId === this.activeVideoDevice) {
            deviceFound = true
          }
        })
        let deviceFeedback = {
          'video_input': this.videoDevices,
          'audio_input': this.audioDevices,
          'audio_output': this.outputDevices
        }
        if (!deviceFound) {
          console.log('The video device appears to be unplugged / no longer available, ending call.')
          this.stopLocalStreams()
          this.EventBus.$emit('onStreamNoLongerAvailable', {
            'devices': deviceFeedback
          })
          window.LoggerAPI.addLog('client', new Date(), 'log', 'STREAM_NO_LONGER_AVAILABLE', 'The video/audio stream is no longer available, for example because the webcam was unplugged.')
        }
      }
    }, 3000)
  }

  initializeEffectsSDK () {
    if (this.effectsSDK) {
      return
    }
    // eslint-disable-next-line new-cap
    this.effectsSDK = new tsvb(process.env.VUE_APP_EFFECTS_SDK_KEY)
    this.effectsSDK.config({
      preset: 'balanced',
      provider: 'auto',
      proxy: true,
      wasmPaths: {
        'ort-wasm.wasm': 'https://effectssdk.ai/sdk/web/3.4.3/ort-wasm.wasm',
        'ort-wasm-simd.wasm': 'https://effectssdk.ai/sdk/web/3.4.3/ort-wasm-simd.wasm'
      }
    })
    this.effectsSDK.cache()
    this.effectsSDK.preload()
  }
  resetEffectsSDK () {
    if (!this.effectsSDK) {
      return
    }
    delete this.effectsSDK
    // eslint-disable-next-line new-cap
    this.effectsSDK = new tsvb(process.env.VUE_APP_EFFECTS_SDK_KEY)
    this.effectsSDK.config({
      preset: 'balanced',
      provider: 'auto',
      proxy: true,
      wasmPaths: {
        'ort-wasm.wasm': 'https://effectssdk.ai/sdk/web/3.4.3/ort-wasm.wasm',
        'ort-wasm-simd.wasm': 'https://effectssdk.ai/sdk/web/3.4.3/ort-wasm-simd.wasm'
      }
    })
    this.effectsSDK.cache()
    this.effectsSDK.preload()
  }
  disconnect (emitDisconnect) {
    console.log('TOTALLY DISCONNECTED')

    if (this.connection !== null) {
      this.connection.close()
      this.connection = null
    }
    if ((typeof emitDisconnect === 'undefined' || emitDisconnect !== false) && this.forcedLogout === false && this.pageUnloaded === false) {
      console.log('EMIT MY DISCONNECT')
      this.EventBus.$emit('onDisconnected')
    }
    if (this.room) {
      console.log('DISCONNECT FROM ROOM')
      this.room.disconnect()
    }
  }

  resetIsInitialConnection () {
    if (this.connection) {
      this.connection.auth.isInitialConnection = true
    }
  }

  forceLogout (emitForcedLogout) {
    this.forcedLogout = true
    if (this.connection !== null) {
      this.connection.close()
      this.connection = null
    }
    if (typeof emitForcedLogout === 'undefined' || emitForcedLogout !== false) {
      this.EventBus.$emit('onForcedLogout')
    }
  }

  dataURItoFile (dataURI, fileName) {
    const blob = this.dataURItoBlob(dataURI)
    return new File([blob], fileName)
  }

  dataURItoBlob (dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1])

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length)
    let ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: mimeString })
  }

  convertDataURIToBinary (dataURI) {
    let BASE64_MARKER = ';base64,'
    let base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    let base64 = dataURI.substring(base64Index)
    let raw = window.atob(base64)
    let rawLength = raw.length
    let array = new Uint8Array(new ArrayBuffer(rawLength))

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }
  cancelInvite (agentId, roomId, accountId) {
    window.LoggerAPI.addLog('client', new Date(), 'log', 'CANCEL_INVITE', 'Participant cancels invitation or call forward to agent ' + agentId + '.')
    this.connection.emit('cancel invite', agentId, roomId, accountId)
  };

  connect () {
    this.forcedLogout = false

    if (!this.connection) {
      let token
      if (store.getters.getAccessToken !== null) {
        token = store.getters.getAccessToken
      } else if (store.getters.getParticipantAccessToken !== null) {
        token = store.getters.getParticipantAccessToken
      } else if (store.getters.getSiteAccessToken !== null) {
        token = store.getters.getSiteAccessToken
      }

      // eslint-disable-next-line no-undef
      this.connection = io(process.env.VUE_APP_WEBSOCKET_HOST, {
        reconnection: true,
        reconnectionDelay: 1500,
        reconnectionDelayMax: 1500,
        reconnectionAttempts: 9,
        randomizationFactor: 0,
        timeout: 2000,
        transports: ['websocket'],
        auth: {
          token: token,
          isInitialConnection: true
        }
      })

      this.connection.on('connect', () => {
        this.connection.auth.isInitialConnection = false
        console.log('Successfully connected to socket.io server.')
        this.EventBus.$emit('onConnected')
      })

      this.connection.on('PARTICIPANT_ALREADY_ACTIVE', () => {
        this.EventBus.$emit('onVisitorAlreadyActive')
      })

      this.connection.on('agent_status_changed', (agentId, accountId) => {
        console.log('THis is agent_status_changed')
        this.EventBus.$emit('onAgentStatusChanged', {
          'agentId': agentId,
          'accountId': accountId
        })
      })

      this.connection.on('visitor online', (username, action, agentId, participantBSN) => {
        this.EventBus.$emit('onVisitorOnline', username, action, agentId, participantBSN)
      })

      this.connection.on('visitor offline', (username) => {
        this.EventBus.$emit('onVisitorOffline', username)
      })

      this.connection.on('remote_agent_disconnect', (userId) => {
        // this.EventBus.$emit('onRemoteAgentDisconnect', userId)
      })

      this.connection.on('remote_participant_disconnect', (participantId) => {
        // this.EventBus.$emit('onRemoteParticipantDisconnect', participantId)
      })

      this.connection.on('remote_agent_reconnect', (userId) => {
        // this.EventBus.$emit('onRemoteAgentReconnect', userId)
        // only works if it's direct & two-way, re-establishes your stream to the reconnected user
        this.reconnectStreamTwoWayDirect()
      })

      this.connection.on('remote_participant_reconnect', (participantId) => {
        // this.EventBus.$emit('onRemoteParticipantReconnect', participantId)
        // only works if it's direct & two-way, re-establishes your stream to the reconnected user
        this.reconnectStreamTwoWayDirect()
      })

      // when a visitor requested the agent to join a room
      this.connection.on('request_join', (room, account, requestedBy, action, product, isTwoWay) => {
        window.LoggerAPI.addLog('client', new Date(), 'log', 'REQUEST_JOIN', 'Participant wants to join the room.')
        console.log('Received request to join room ' + room + ' for account ' + account)

        // dispatch offer
        this.EventBus.$emit('onRequestJoin', {
          'room_id': room,
          'account': account,
          'requestedBy': requestedBy,
          'action': action,
          'product': product,
          'stream_camera_two_way': isTwoWay
        })
      })

      // Join the room
      this.connection.on('join', async (roomId, roomPassword, isFirstParticipant, realName, participantId, product, isTwoWay, meetingId) => {
        console.log('RECEIVED JOIN', roomId, roomPassword, isFirstParticipant, realName, participantId, product, isTwoWay, meetingId)
        this.setStatus('busy', true, false, false)

        window.LoggerAPI.addLog('client', new Date(), 'log', 'RECEIVED_JOIN', 'Participant received request to join the room.')
        window.LoggerAPI.addLog('client', new Date(), 'log', 'BROWSER_INFO', JSON.stringify(window.DetectRTC, undefined, 2))

        this.EventBus.$emit('onJoin', {
          'meeting_id': meetingId,
          'room_id': roomId,
          'room_password': roomPassword,
          'is_first_participant': isFirstParticipant,
          'real_name': realName,
          'product': product,
          'stream_camera_two_way': isTwoWay
        })

        this.openOrJoin(roomId, roomPassword, product, isTwoWay)
      })

      // Leave the room
      this.connection.on('leave', (isForcedDisconnect) => {
        window.LoggerAPI.addLog('client', new Date(), 'log', 'CALL_LEFT', 'Participant wants to leave the call.')
        this.endCall(isForcedDisconnect)
      })

      // Get promoted
      this.connection.on('promoted', () => {
        window.LoggerAPI.addLog('client', new Date(), 'log', 'AGENT_PROMOTED', 'Agent promoted')
        this.EventBus.$emit('onPromoted')
      })

      this.connection.on('session_unavailable', (errorData) => {
        this.EventBus.$emit('onRoomUnavailable', {
          'data': errorData
        })
      })

      this.connection.on('start-meeting-counter', () => {
        this.EventBus.$emit('start-meeting-counter')
      })

      // Define Socket.IO connection related callbacks
      this.connection.on('disconnect', (reason) => {
        console.log('Disconnected!', reason)

        if (!reason.includes('disconnect')) {
          this.EventBus.$emit('onLostConnection')
        }

        window.LoggerAPI.addLog('client', new Date(), 'log', 'CLIENT_DISCONNECTED', 'Disconnected from the server.')

        if (status !== 'offline' && this.pageUnloaded === false) {
          this.setStatus('offline')
        }
      })

      this.connection.io.on('reconnect', () => {
        this.EventBus.$emit('onReconnected')
        if (store.getters.getProduct === 'direct') {
          this.startLocalStreams(this.lastProduct, this.lastStreamBothWays, undefined, this.activeVideoDevice, null, null, undefined, undefined, undefined)
        }
      })

      this.connection.io.on('reconnect_attempt', (attempt) => {
        this.EventBus.$emit('onReconnectAttempt', {
          attempt: attempt
        })
      })

      this.connection.io.on('reconnect_error', (error) => {
        console.log('Reconnect attempt failed', error)
        // this.EventBus.$emit('onReconnectAttemptFailed')
      })

      this.connection.io.on('reconnect_failed', () => {
        console.log('RECONNECT FAILED')
        this.EventBus.$emit('onReconnectFailed')
        this.disconnect()
      })

      this.connection.on('force-logout', () => {
        window.LoggerAPI.addLog('client', new Date(), 'log', 'CLIENT_DISCONNECTED', 'Disconnected from the server.')

        if (status !== 'offline') {
          this.setStatus('offline')
        }
        console.log('Forced logout! Disconnecting socket')
        this.forceLogout()
      })

      this.connection.on('connect_timeout', () => {
        console.log('SocketIO connection timed out')
      })

      this.connection.on('connect_error', (error) => {
        console.log('SocketIO connection failed:', error)
        // this.EventBus.$emit('onConnectError')
      })

      this.connection.on('error', (error) => {
        console.log('SocketIO connection error:', error)
        // this.EventBus.$emit('onConnectError')
      })

      this.connection.on('setStatus', (status) => {
        console.log('Received new status ' + status)
        this.setStatus(status, false)
      })

      this.connection.on('converted presentation', (pdf, filename, filesize, signature) => {
        let fileObject = this.dataURItoFile(pdf, 'presentation.pdf')

        this.EventBus.$emit('onPresentationConverted', fileObject, signature)
      })

      this.connection.on('chat_attachment_and_converted_presentation', (file, filename, filesize, signature) => {
        let fileObject = this.dataURItoFile(file, filename)

        this.EventBus.$emit('onPresentationByApi', fileObject, signature)
        this.EventBus.$emit('onShareFileByApi', fileObject)
      })

      this.connection.on('chat_attachment', (file, filename) => {
        let fileObject = this.dataURItoFile(file, filename)
        this.EventBus.$emit('onShareFileByApi', fileObject)
      })

      this.connection.on('conversion failed', () => {
        this.EventBus.$emit('onPresentationConversionFailed')
      })

      this.connection.on('cancel_offer', (roomId) => {
        // TODO: Make sure logger api works with this again
        window.LoggerAPI.addLog('client', new Date(), 'log', 'CANCEL_OFFER', 'The call offer was cancelled.')
        this.EventBus.$emit('onCancelOffer', {
          'roomId': roomId
        })
      })

      this.connection.on(`${store.getters.getUser.id}-scriptix transcription call received`, (data) => {
        this.EventBus.$emit('onScriptixTranscriptionReceived', {
          'data': data
        })
      })
      this.connection.on(`zynyo-signing-link-ready`, (data) => {
        this.EventBus.$emit('onZynyoSigningLinkReady', data)
      })
      this.connection.on(`zynyo-participant-request`, (data) => {
        this.EventBus.$emit('onZynyoEmailRequest', data)
      })
      this.connection.on('zynyo-participant-request-response', (data) => {
        this.EventBus.$emit('onZynyoEmailResponse', data)
      })

      this.connection.on('call held', (roomId, agentName) => {
        window.LoggerAPI.addLog('client', new Date(), 'log', 'CALL_HELD', 'The call was held.')
        this.EventBus.$emit('onCallHeld', {
          'roomId': roomId,
          'agentFullName': agentName
        })
      })

      this.connection.on('declined', (roomId, agentName) => {
        window.LoggerAPI.addLog('client', new Date(), 'log', 'CALL_DECLINED', 'The call was declined.')
        this.EventBus.$emit('onCallDeclined', {
          'roomId': roomId,
          'agentFullName': agentName
        })
      })

      this.connection.on('call colleague failed', () => {
        this.EventBus.$emit('onCallColleagueFailed')
      })

      this.connection.on('license_error', (permission) => {
        console.log(permission) // The permission that was denied by the API, for example "chat", "forward", it means that the user is not licensed to do what was requested
        // TODO: This license thing is not checked too well, the newest events probably are never checked at all, not important for now
        // The current Personal shows a dialog that tells you that you are not licensed to do that
        // For almost any event this event would have been caught already by the UI, but it is better to server-sided check for license requirements as well consistently
      })

      // Leave the room because of a call forward
      this.connection.on('forward', () => {
        // TODO: Make sure logger api works with this again
        window.LoggerAPI.addLog('client', new Date(), 'log', 'CALL_FORWARDED', 'Participant leaves because the call was forwarded.')

        // The idea here is that the call will stop and then restart with the new agent as the host
        // This will very likely break stuff in the new app, let's fix that together
        this.endCall()
      })

      this.connection.on('meeting_updated', (meeting) => {
        if (typeof meeting === 'undefined' || meeting === null) {
          meeting = null
        }
        try {
          meeting = JSON.parse(meeting)
        } catch (e) {
          // do nothing
        }

        this.EventBus.$emit('onMeetingUpdated', meeting)
      })

      this.connection.on('authenticated', () => {
        console.warn('User logged in succesfully')

        if (store.getters.getProduct === 'personal') {
          this.setStatus(store.getters.getStatus === 'online' || store.getters.isUINavigationEnabled === false ? 'online' : 'offline', true, store.getters.isUINavigationEnabled, false)
        } else {
          this.setStatus('online', true, false)
        }

        this.EventBus.$emit('onAuthenticated')
      })

      this.connection.on('call colleague join', (roomId) => {
        this.EventBus.$emit('onCallColleagueAccept', {
          'roomId': roomId
        })
        this.join(roomId, 'personal', true, true)
      })
    }
  };

  sendOrientationChanged (orientation) {
    let sender = this.getRealNameFromStream(this.localStream)
    this.localStream.sendData({
      'sender': sender,
      'type': 'orientation_changed',
      'orientation': orientation
    })
  };

  showPopupDigid () {
    console.log('received emit show popup')
    if (this.localStream) {
      let sender = this.getRealNameFromStream(this.localStream)
      this.localStream.sendData({
        'sender': sender,
        'type': 'popup_digid'
      })
      this.EventBus.$emit('onShowPopupDigid', {
        'sender': sender,
        'type': 'popup_digid'
      })
    }
  }

  sendChatMessage (message) {
    message = message.replace(/^\s+|\s+$/g, '')
    if (!message.length) return

    if (this.localStream) {
      let now = new Date()
      let sender = this.getRealNameFromStream(this.localStream)
      this.localStream.sendData({
        'sender': sender,
        'type': 'chat',
        'content': message,
        'datetime': now
      })
      this.EventBus.$emit('onChatMessage', {
        'type': 'chat',
        'sender': sender,
        'message': message,
        'direction': 'out',
        'datetime': now
      })
    }
  }

  sendRaiseOrLowerHand (raiseHand) {
    if (this.localStream) {
      let senderName = this.getRealNameFromStream(this.localStream)
      let senderId = this.getUserIdFromStream(this.localStream)
      this.localStream.sendData({
        'sender': senderName,
        'senderId': senderId,
        'type': 'raiseOrLowerHand',
        'raiseHand': raiseHand
      })
    }
  }

  setStatus (newStatus, emitStatus, fireStatusChangedEvent, emitStore) {
    this.status = newStatus

    if (this.connection && (typeof emitStatus === 'undefined' || emitStatus === true)) {
      console.log('Set status, emit: ' + this.status)
      this.connection.emit('set status', this.status)
    } else {
      console.log('Set status, dont emit: ' + this.status)
    }

    if (typeof fireStatusChangedEvent === 'undefined' || fireStatusChangedEvent === true) {
      this.EventBus.$emit('onStatusChanged', {
        'status': this.status
      })
    }
    if (typeof emitStore === 'undefined' || emitStore === true) {
      store.commit('setStatus', this.status)
    }
  }

  reconnectStreamTwoWayDirect () {
    if (store.getters.getProduct === 'direct') {
      if (this.lastStreamBothWays) {
        this.startLocalStreams(this.lastProduct, this.lastStreamBothWays, undefined, this.activeVideoDevice, null, null, undefined, undefined, undefined)
      }
    }
  }

  inviteAgent (agentId, roomId, accountId) {
    window.LoggerAPI.addLog('client', new Date(), 'log', 'INVITE_AGENT', 'Participant invited agent ' + agentId + ' to join.')

    this.connection.emit('invite agent', agentId, roomId, accountId)
  }

  forwardToAgent (agentId, roomId, accountId) {
    window.LoggerAPI.addLog('client', new Date(), 'log', 'FORWARD_CALL', 'Participant wants to forward the call to agent ' + agentId + '.')
    this.connection.emit('forward to agent', agentId, roomId, accountId)
  }

  callColleague (agentId) {
    window.LoggerAPI.addLog('client', new Date(), 'log', 'CALL_COLLEAGUE', 'Call colleague ' + agentId + '.')

    this.connection.emit('call colleague', agentId)
  }

  callColleagueAccept (roomId) {
    window.LoggerAPI.addLog('client', new Date(), 'log', 'CALL_COLLEAGUE_ACCEPTED', 'Call accepted by colleague.')

    this.connection.emit('call colleague accept', roomId)
  }

  syncPresentation (scale, width, offsetX, offsetY, drawings) {
    this.localStream.sendData({
      'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
      'type': 'sync_presentation',
      'scale': scale,
      'offsetX': offsetX,
      'offsetY': offsetY,
      'drawings': drawings,
      'width': width
    })
  }

  endCall (isForcedDisconnect) {
    window.LoggerAPI.addLog('client', new Date(), 'log', 'HANGUP', 'Participant wants to hangup.')
    this.stopLocalStreams()
    this.stopScreenSharing()
    this.connection.emit('hangup')
    this.isAudioMuted = false
    this.isVideoMuted = false
    this.resetEffectsSDK()
    this.EventBus.$emit('onEndCall', isForcedDisconnect)
  };
  stopLocalStreams () {
    this.stopScreenSharing()
    if (this.uploadBumicomRecording) {
      this.stopAutoRecording(this.axiosInstance)
    } else {
      this.stopRecording()
    }

    if (this.localStream) {
      console.log('Localstream is found, stop it', this.localStream)
      // If we already have the stream available, simply shut it down right away
      if (this.localStream !== null && typeof this.localStream.stream !== 'undefined' && this.localStream.stream.getVideoTracks().length > 0) {
        this.localStream.close()
        this.localStream = null
        this.activeVideoDevice = null
        this.activeAudioInput = null
        this.activeAudioOutput = null
        return
      }

      // Otherwise we have to wait for it to become "ready" before we can close it
      let giveUp = 100
      let stopInterval = setInterval(() => {
        if (this.localStream === null) {
          clearInterval(stopInterval)
          return
        }
        if (giveUp <= 0) {
          clearInterval(stopInterval)
          this.activeVideoDevice = null
          this.activeAudioInput = null
          this.activeAudioOutput = null
          this.localStream = null
          return
        }
        if (this.localStream !== null && typeof this.localStream.stream !== 'undefined' && this.localStream.stream.getVideoTracks().length > 0) {
          clearInterval(stopInterval)
          this.localStream.close()
          this.activeVideoDevice = null
          this.activeAudioInput = null
          this.activeAudioOutput = null
          this.localStream = null
        }
        giveUp--
      }, 100)
    }
    this.EventBus.$emit('onStoppedLocalStreams')
    if (this.room) {
      this.room.disconnect()
    }
  }

  stopScreenSharing () {
    if (this.localStreamScreen) {
      this.localStreamScreen.close()
      this.localStreamScreen = null
      this.EventBus.$emit('onScreensharingStopped')
      window.LoggerAPI.addLog('client', new Date(), 'log', 'STOP_SCREENSHARING', 'Screen sharing stopped.')
    }
  }

  getConstraints (product, streamBothWays, deviceIdVideoInput, deviceIdAudioInput, isCamSettings) {
    if (product === 'weseedo_direct') {
      if (streamBothWays) {
        this.facingMode = 'user'
      } else {
        this.facingMode = 'environment'
      }
    } else {
      this.facingMode = 'user'
    }

    let constraints = {
      audio: true,
      video: {
        width: { ideal: 640 }, // min: 640, max: 1920
        height: { ideal: 360 }, // min :480, max: 1080
        facingMode: { ideal: this.facingMode },
        focusMode: { ideal: 'continuous' }
      },
      data: true,
      local: true
    }

    // This is a switch of devices, keep all constraints the same except for devices
    if (deviceIdVideoInput || deviceIdAudioInput) {
      constraints.video.deviceId = { exact: deviceIdVideoInput || this.activeVideoDevice }
      if (typeof constraints.audio === 'boolean') {
        constraints.audio = { deviceId: { exact: deviceIdAudioInput || this.activeAudioInput } }
      } else {
        constraints.audio.deviceId = { exact: deviceIdAudioInput || this.activeAudioInput }
      }
    }

    let browser = window.DetectRTC.browser.name
    let isMobileDevice = window.DetectRTC.isMobileDevice
    if (!isMobileDevice) {
      isMobileDevice = /iPad/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    }

    let meeting = store.getters.getMeeting
    if (meeting) {
      let participantCount = 2
      let multiplier = 1
      if (isMobileDevice) {
        multiplier = 0.5
      }
      if (typeof meeting.participant_count !== 'undefined') {
        participantCount = meeting.participant_count
      } else if (typeof meeting.participants !== 'undefined') {
        participantCount = meeting.participants.length
      }

      if (isCamSettings && isCamSettings === true) {
        constraints.video.width.ideal = 1280 * multiplier
        constraints.video.height.ideal = 720 * multiplier
      } else {
        if (participantCount === 2) {
          constraints.video.width.ideal = 1280 * multiplier
          constraints.video.height.ideal = 720 * multiplier
        } else if (participantCount === 3 || participantCount === 4) {
          constraints.video.width.ideal = 640 * multiplier
          constraints.video.height.ideal = 360 * multiplier
        } else if (participantCount === 5 || participantCount === 6 || participantCount === 7 || participantCount === 8 || participantCount === 9) {
          constraints.video.width.ideal = 320
          constraints.video.height.ideal = 180
        } else {
          constraints.video.width.ideal = 160
          constraints.video.height.ideal = 90
        }
      }
    }

    if (!isMobileDevice) {
      if (browser === 'Firefox') {
        constraints.video.facingMode = this.facingMode
      }
    } else {
      if (browser === 'Firefox') {
        constraints.video.facingMode = this.facingMode
      }
    }

    let userId = null
    let realName = ''

    if (store.getters.getUser !== false) {
      userId = store.getters.getUser.id
      realName = this.setFullName(store.getters.getUser)

      if (product === 'weseedo_direct') {
        constraints.audio = false
        if (browser === 'Firefox') {
          constraints.audio = true // this is to enable firefox to record video
        }
        if (!streamBothWays) {
          constraints.video = false
        }
      }
    } else {
      if (product === 'weseedo_direct') {
        userId = store.getters.getMeeting.participant.id
        realName = 'Bezoeker'
        constraints.audio = false
      } else {
        userId = store.getters.getMeeting.participant.id
        realName = store.getters.getMeeting.participant.name
      }
    }

    constraints.attributes = {
      'user_id': userId,
      'real_name': realName
    }
    this.EventBus.$emit('onAskStreamPermissions')

    return constraints
  }

  setFullName (user) {
    if (user !== false) {
      if (typeof user.first_name !== 'undefined' && typeof user.last_name !== 'undefined') {
        return (user.first_name + ' ' + user.last_name).trim()
      } else if (typeof user.first_name !== 'undefined' && typeof user.last_name === 'undefined') {
        return user.first_name
      } else if (typeof user.first_name === 'undefined' && typeof user.last_name !== 'undefined') {
        return user.last_name
      } else {
        return 'Agent'
      }
    } else {
      return 'Agent'
    }
  }

  tmpVarsCamera = {
    product: null,
    streamBothWays: null,
    callback: null
  }

  lastProduct = null
  lastStreamBothWays = null

  switchCameraDevice (deviceId, callback, isCamSettings) {
    if (this.deviceSwitching) {
      return
    }
    this.deviceSwitching = true
    if (deviceId === this.activeVideoDevice) {
      this.deviceSwitching = false
      return
    }

    let facingMode = null
    if (typeof this.localStream.stream !== 'undefined' && this.localStream.stream.getVideoTracks().length > 0) {
      facingMode = this.localStream.stream.getVideoTracks()[0].getSettings().facingMode
    }
    this.facingMode = facingMode
    /* await this.localStream.stream.getTracks().forEach(track => {
      track.stop()
    }) */
    this.facingMode = 'user'
    this.startLocalStreams(this.lastProduct, this.lastStreamBothWays, callback, deviceId, null, null, undefined, undefined, isCamSettings)
    this.deviceSwitching = false
  }

  switchAudioInputDevice (deviceId, callback) {
    if (this.deviceSwitching) {
      return
    }
    this.deviceSwitching = true
    if (deviceId === this.activeAudioInput) {
      this.deviceSwitching = false
      return
    }

    /* this.localStream.stream.getTracks().forEach(track => {
      track.stop()
    }) */

    this.startLocalStreams(this.lastProduct, this.lastStreamBothWays, callback, null, deviceId, null)
  }

  switchAudioOutputDevice (deviceId, videos, callback) {
    if (this.deviceSwitching) {
      return
    }
    this.deviceSwitching = true
    if (deviceId === this.activeAudioOutput) {
      this.deviceSwitching = false
    } else if (deviceId !== null && deviceId !== '') {
      this.activeAudioOutput = deviceId
    }
    videos.forEach((video) => {
      video.setSinkId(this.activeAudioOutput)
    })

    this.deviceSwitching = false
  }

  // Attach audio output device to video element using device/sink ID.
  attachSinkId (element, sinkId) {
    if (!element) {
      return
    }

    if (typeof element.sinkId !== 'undefined') {
      element.setSinkId(sinkId)
        .then(() => {
          this.activeAudioOutput = sinkId
          console.log('Success, audio output device attached: ' + sinkId)
        })
        .catch(error => {
          let errorMessage = error
          console.error(errorMessage)
        })
    } else {
      console.warn('Browser does not support output device selection.')
    }
  }

  startSoundMeter () {
    try {
      let AudioContext = window.AudioContext || window.webkitAudioContext
      this.soundMeter = new SoundMeter(new AudioContext())
    } catch (e) {
      console.log('SoundMeter not supported.')
      return
    }

    this.soundMeter.connectToSource(this.localStream.stream, (e) => {
      if (e) {
        return
      }
      this.soundMeterInterval = setInterval(() => {
        let currentValue = parseFloat(this.soundMeter.instant).toFixed(2)
        this.EventBus.$emit('onMicrophoneVolume', {
          'volume': currentValue
        })
      }, 250)
    })
  }

  setP2P (p2p) {
    this.p2p = p2p
  }
  async initializeVirtualEffect (backgroundImage) {
    this.backgroundImage = backgroundImage
    this.isVideoEffectRunning = 'virtual'
    this.effectsSDK.onReady = () => {
      this.effectsSDK.stop()
      this.effectsSDK.run()
      this.effectsSDK.clearBackground()
      this.effectsSDK.clearBlur()
      this.effectsSDK.setBackground(this.backgroundImage)
    }
    try {
      this.effectsSDK.getStream()
      this.effectsSDK.run()
      this.effectsSDK.clearBackground()
      this.effectsSDK.clearBlur()
      this.effectsSDK.setBackground(this.backgroundImage)
    } catch (e) {
      this.effectsSDK.useStream(this.localStream.stream)
    }
    setTimeout(() => {
      let localVideoElement = document.querySelector('#local') || document.querySelector(`#${this.localStream?.getID()}`)
      localVideoElement.srcObject = this.effectsSDK.getStream()
    }, 1500)
    clearInterval(this.replaceStreamWaitForPCInterval)
    this.replaceStreamWaitForPCInterval = setInterval(() => {
      this.replaceTracksInPeerConnection(this.effectsSDK.getStream().getVideoTracks()[0], this.localStream.stream.getAudioTracks()[0])
    }, 1000)
  }
  async initializeBlurringEffect () {
    this.isVideoEffectRunning = 'blurring'
    this.effectsSDK.onReady = () => {
      this.effectsSDK.run()
      this.effectsSDK.clearBackground()
      this.effectsSDK.clearBlur()
      this.effectsSDK.setBlur(1)
    }
    try {
      this.effectsSDK.getStream()
      this.effectsSDK.run()
      this.effectsSDK.clearBackground()
      this.effectsSDK.clearBlur()
      this.effectsSDK.setBlur(1)
    } catch (e) {
      this.effectsSDK.useStream(this.localStream.stream)
    }
    setTimeout(() => {
      let localVideoElement = document.querySelector('#local') || document.querySelector(`#${this.localStream?.getID()}`)
      localVideoElement.srcObject = this.effectsSDK.getStream()
    }, 1500)
    clearInterval(this.replaceStreamWaitForPCInterval)
    this.replaceStreamWaitForPCInterval = setInterval(() => {
      this.replaceTracksInPeerConnection(this.effectsSDK.getStream().getVideoTracks()[0], this.localStream.stream.getAudioTracks()[0])
    }, 1000)
  }
  async reloadVideoEffect () {
    this.effectsSDK.clear()
    if (this.isVideoEffectRunning === 'virtual') {
      this.effectsSDK.onReady = () => {
        this.effectsSDK.run()
        this.effectsSDK.setBackground(this.backgroundImage)
      }
    } else if (this.isVideoEffectRunning === 'blurring') {
      this.effectsSDK.onReady = () => {
        this.effectsSDK.run()
        this.effectsSDK.setBlur(1)
      }
    } else {
      return
    }
    this.effectsSDK.useStream(this.localStream.stream)
    setTimeout(() => {
      let localVideoElement = document.querySelector('#local') || document.querySelector(`#${this.localStream?.getID()}`)
      localVideoElement.srcObject = this.effectsSDK.getStream()
    }, 1500)
    clearInterval(this.replaceStreamWaitForPCInterval)
    this.replaceStreamWaitForPCInterval = setInterval(() => {
      this.replaceTracksInPeerConnection(this.effectsSDK.getStream().getVideoTracks()[0], this.localStream.stream.getAudioTracks()[0])
    }, 1000)
  }
  async reloadEffectsForRemoteParticipants () {
    clearInterval(this.replaceStreamWaitForPCInterval)
    this.replaceStreamWaitForPCInterval = setInterval(() => {
      this.replaceTracksInPeerConnection(this.effectsSDK.getStream().getVideoTracks()[0], this.localStream.stream.getAudioTracks()[0])
    }, 1000)
  }
  async removeVideoEffect () {
    this.effectsSDK.stop()
    clearInterval(this.replaceStreamWaitForPCInterval)
    if (!this.localStream) {
      this.isVideoEffectRunning = false
      return
    }
    let localVideoElement = document.querySelector('#local') || document.querySelector(`#${this.localStream?.getID()}`)
    localVideoElement.srcObject = this.localStream.stream
    this.replaceStreamWaitForPCInterval = setInterval(() => {
      this.replaceTracksInPeerConnection(this.localStream.stream.getVideoTracks()[0], this.localStream.stream.getAudioTracks()[0])
    }, 1000)
    this.isVideoEffectRunning = false
  }
  /**
   * Replaces the video track in the peer connection with the specified track.
   *
   * @param {MediaStreamTrack} replaceVideoTrack - The video track to replace.
   * @param {MediaStreamTrack} replaceAudioTrack - The audio track to replace.
   */
  replaceTracksInPeerConnection (replaceVideoTrack, replaceAudioTrack) {
    let pc = this.getPeerConnection()
    let senderFound = false
    if (pc) {
      if (pc.forEach) {
        pc.forEach((pcInstance) => {
          senderFound = this.processSender(pcInstance, replaceVideoTrack, replaceAudioTrack)
        })
      } else {
        senderFound = this.processSender(pc, replaceVideoTrack, replaceAudioTrack)
      }

      if (senderFound) {
        clearInterval(this.replaceStreamWaitForPCInterval)
      }
    }
  }
  /**
   * Processes the sender in the peer connection and replaces the video track.
   * @param {RTCPeerConnection} pc - The peer connection object.
   * @param {MediaStreamTrack} replaceVideoTrack - The video track to replace.
   * @param {MediaStreamTrack} replaceAudioTrack - The audio track to replace.
   * @returns {boolean} - Indicates whether the sender was found and processed.
   */
  processSender (pc, replaceVideoTrack, replaceAudioTrack) {
    const senderVideo = pc.peerConnection.getSenders().find(s => s.track.kind === replaceVideoTrack.kind)
    const senderAudio = pc.peerConnection.getSenders().find(s => s.track.kind === replaceAudioTrack.kind)
    if (senderVideo || senderAudio) {
      if (senderVideo) {
        senderVideo.replaceTrack(replaceVideoTrack).catch((err) => {
          console.error(err)
        })
      }
      if (senderAudio) {
        senderAudio.replaceTrack(replaceAudioTrack).catch((err) => {
          console.error(err)
        })
      }
      clearInterval(this.replaceStreamWaitForPCInterval)
      return true // Indicates sender was found and processed
    }
    return false // Indicates no sender was found
  }
  /**
   * Initializes the Rnnoise noise suppression for audio processing.
   *
   * @return {Promise} A promise that resolves when the Rnnoise noise suppression is successfully initialized.
   */
  async initializeRnnoise () {
    // Create a new AudioContext for audio processing.
    this.noiseSuppressionContext = new AudioContext()
    // Load the RNNoise WebAssembly (Wasm) binary asynchronously. This is used for noise suppression.
    // The `loadRnnoise` function takes URLs for the normal and SIMD (Single Instruction, Multiple Data) optimized versions of the RNNoise Wasm.
    const rnnoiseWasmBinary = await loadRnnoise({ url: rnnoiseWasmPath, simdUrl: rnnoiseWasmSimdPath })
    // Add the RNNoise audio worklet module to the AudioContext. This allows custom audio processing within the browser's audio engine.
    await this.noiseSuppressionContext.audioWorklet.addModule(rnnoiseWorkletPath)
    // Retrieve the original audio track from the local media stream.
    const originalAudioTrack = this.localStream.stream.getAudioTracks()[0]
    // Create a MediaStreamSource node from the original audio track. This node acts as the input to the audio processing graph.
    const source = this.noiseSuppressionContext.createMediaStreamSource(new MediaStream([originalAudioTrack]))
    // Create an instance of RnnoiseWorkletNode, which processes the audio to suppress noise.
    // It uses the loaded RNNoise Wasm binary and is configured to process a single audio channel.
    const rnnoise = new RnnoiseWorkletNode(this.noiseSuppressionContext, {
      wasmBinary: rnnoiseWasmBinary,
      maxChannels: 1
    })
    // Connect the source (original audio) to the RNNoise worklet node to start the noise suppression processing.
    source.connect(rnnoise)
    // Create a MediaStreamDestination node, which collects the processed audio output.
    const destination = this.noiseSuppressionContext.createMediaStreamDestination()
    // Connect the RNNoise output to the destination node to prepare the processed audio for output.
    rnnoise.connect(destination)
    // Extract the filtered (processed) audio track from the destination stream.
    const filteredAudioTrack = destination.stream.getAudioTracks()[0]
    // Remove the original (unprocessed) audio track from the local stream.
    this.localStream.stream.removeTrack(originalAudioTrack)
    // Add the filtered (noise-suppressed) audio track to the local stream.
    this.localStream.stream.addTrack(filteredAudioTrack)
  }
  lastConstraints = null
  startLocalStreams (product, streamBothWays, callback, deviceIdVideoInput, deviceIdAudioInput, deviceIdAudioOutput, videoMuted, audioMuted, isCamSettings) {
    console.log('IS MUTED?', videoMuted, audioMuted)
    if (typeof videoMuted !== 'undefined') {
      this.isVideoMuted = videoMuted
    }
    if (typeof audioMuted !== 'undefined') {
      this.isAudioMuted = audioMuted
    }
    console.log('IS MUTED NOW?', this.isVideoMuted, this.isAudioMuted)
    if (deviceIdVideoInput === false) {
      this.activeVideoDevice = null
    }
    if (deviceIdAudioInput === false) {
      this.activeAudioInput = null
    }
    if (deviceIdAudioOutput === false) {
      this.activeAudioOutput = null
    } else if (deviceIdAudioOutput && deviceIdAudioOutput !== '') {
      this.activeAudioOutput = deviceIdAudioOutput
    }

    this.lastProduct = product
    this.lastStreamBothWays = streamBothWays
    console.log('hasLocalStream', this.hasLocalStream())
    if (this.hasLocalStream() && !deviceIdVideoInput && !deviceIdAudioInput) {
      console.log('Localstream is not null')
      if (typeof callback !== 'undefined') {
        callback(this.localStream)
      }
    } else {
      console.log('Localstream is null or config updated, getConstraints')
      let constraints = this.getConstraints(product, streamBothWays, deviceIdVideoInput, deviceIdAudioInput, isCamSettings)
      // setup constraints for noise cancellation
      if (typeof constraints.audio === 'object') {
        constraints.audio = {
          ...constraints.audio,
          echoCancellation: true,
          echoCancellationType: { ideal: 'system' },
          channelCount: 1,
          noiseSuppression: false,
          autoGainControl: true,
          googEchoCancellation: false,
          googAutoGainControl: false,
          googExperimentalAutoGainControl: false,
          googNoiseSuppression: false,
          googExperimentalNoiseSuppression: false,
          googHighpassFilter: false,
          googTypingNoiseDetection: false,
          googBeamforming: false,
          googArrayGeometry: false,
          googAudioMirroring: false,
          googNoiseReduction: false,
          mozNoiseSuppression: false,
          mozAutoGainControl: false,
          latency: 0.01
        }
      }
      if (this.room) {
        console.log('localStream to unpublish', this.localStream)
        this.room.unpublish(this.localStream)
        console.log('constraints from getConstraints', constraints)
        console.log('room', this.room)
      }
      if (this.p2p) {
        // eslint-disable-next-line no-undef
        this.localStream = ErizoV9.Stream(constraints)
        console.log('localStream to publish v9', this.localStream)
      } else {
        // eslint-disable-next-line no-undef
        this.localStream = Erizo.Stream(constraints)
        console.log('localStream to publish v10', this.localStream)
      }
      this.lastConstraints = constraints

      this.localStream.addEventListener('bandwidth-alert', (streamEvent) => {
        console.error('THERE IS A BANDWIDTH PROBLEM NORMAL STREAM')
        console.error(streamEvent)
      })
      this.localStream.addEventListener('access-accepted', async (stream) => {
        console.log('stream in access-accepted event listener', stream)
        if (constraints.audio !== false) {
          this.startSoundMeter()
        }
        if (this.isVideoEffectRunning) {
          await this.reloadVideoEffect()
        } else {
          await this.resetEffectsSDK()
        }
        if (this.room) {
          await this.room.publish(this.localStream, {
            disableIceRestart: true,
            forceTurn: true,
            simulcast: false,
            scheme: 'notify-break-recover'
          })
        }

        let devices = await navigator.mediaDevices.enumerateDevices()

        this.videoDevices = []
        this.audioDevices = []
        this.outputDevices = []
        let video = null
        let facingMode = null
        if (constraints.video !== false) {
          if (!('getCapabilities' in this.localStream.stream.getVideoTracks()[0])) {
            facingMode = this.localStream.stream.getVideoTracks()[0].getSettings().facingMode
            this.activeVideoDevice = this.localStream.stream.getVideoTracks()[0].getSettings().deviceId
          } else {
            this.activeVideoDevice = this.localStream.stream.getVideoTracks()[0].getCapabilities().deviceId
          }

          if (this.localStream.stream.getAudioTracks().length > 0) {
            if (!('getCapabilities' in this.localStream.stream.getAudioTracks()[0])) {
              this.activeAudioInput = this.localStream.stream.getAudioTracks()[0].getSettings().deviceId
            } else {
              this.activeAudioInput = this.localStream.stream.getAudioTracks()[0].getCapabilities().deviceId
            }
          } else {
            this.activeAudioInput = null
          }

          video = this.getVideoTag(this.localStream)

          if (this.isVideoMuted) {
            this.muteVideo()
          }
          if (this.isAudioMuted) {
            this.muteAudio()
          }

          if (!facingMode) {
            facingMode = this.facingMode
          }
        }

        let devicesVideo = []
        let devicesAudio = []
        let devicesSpeaker = []
        let replacements = ['Default - ', 'Standaard - ']

        for (let device of devices) {
          device = JSON.parse(JSON.stringify(device))
          if (device.deviceId === 'communications') {
            continue
          }
          for (let replacement of replacements) {
            device.label = device.label.replace(replacement, '')
          }
          if (device.kind === 'audioinput') {
            let index = devicesAudio.indexOf(device.label)
            if (index < 0) {
              devicesAudio.push(device.label)
              this.audioDevices.push(device)
            } else if (device.deviceId === this.activeAudioInput) {
              this.audioDevices.pop()
              this.audioDevices.push(device)
            }
          } else if (device.kind === 'videoinput') {
            let index = devicesVideo.indexOf(device.label)
            if (index < 0) {
              devicesVideo.push(device.label)
              this.videoDevices.push(device)
            } else if (device.deviceId === this.activeVideoDevice) {
              this.videoDevices.pop()
              this.videoDevices.push(device)
            }
          } else if (device.kind === 'audiooutput') {
            let index = devicesSpeaker.indexOf(device.label)
            if (index < 0) {
              devicesSpeaker.push(device.label)
              this.outputDevices.push(device)
            } else if (device.deviceId === this.activeAudioOutput) {
              this.outputDevices.pop()
              this.outputDevices.push(device)
            }
          }
        }

        let isMobileDevice = window.DetectRTC.isMobileDevice
        if (!isMobileDevice) {
          isMobileDevice = /iPad/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
        }

        this.EventBus.$emit('onStreamAccessGranted', {
          'can_switch_camera': isMobileDevice,
          'stream': this.localStream.stream,
          'video': video,
          'mirrored': facingMode === 'user',
          'video_muted': this.isVideoMuted,
          'audio_muted': this.isAudioMuted
        })

        let deviceFeedback = {
          'current_video_input': this.activeVideoDevice,
          'current_audio_input': this.activeAudioInput,
          'current_audio_output': this.activeAudioOutput,
          'devices': this.videoDevices,
          'audio_input': this.audioDevices,
          'audio_output': this.outputDevices
        }

        this.EventBus.$emit('onDeviceList', deviceFeedback)

        if (typeof callback !== 'undefined') {
          callback(this.localStream, deviceFeedback)
        }
      })
      this.localStream.addEventListener('access-denied', async (event) => {
        let devices = await navigator.mediaDevices.enumerateDevices()
        this.videoDevices = []
        this.audioDevices = []
        this.outputDevices = []
        for (const device of devices) {
          if (device.kind === 'videoinput') {
            this.videoDevices.push({
              'label': device.label,
              'deviceId': device.deviceId
            })
          } else if (device.kind === 'audioinput') {
            this.audioDevices.push({
              'label': device.label,
              'deviceId': device.deviceId
            })
          } else if (device.kind === 'audiooutput') {
            this.outputDevices.push({
              'label': device.label,
              'deviceId': device.deviceId
            })
          }
        }

        let deviceFeedback = {
          'video_input': this.videoDevices,
          'audio_input': this.audioDevices,
          'audio_output': this.outputDevices
        }
        this.localStream = null
        this.activeVideoDevice = null
        this.activeAudioInput = null
        this.EventBus.$emit('onStreamAccessDenied', {
          'error': event.msg,
          'devices': deviceFeedback
        })
      })
      this.localStream.init()
    }
  }

  runningStreams = [];
  getAllStreams () {
    return this.runningStreams
  }

  /*
  publishStream (stream) {
    console.log('PUBLISH', stream)
    stream.toLog = function () { console.log('TO LOG CALLED') }
    this.room.publish(stream, { disableIceRestart: true, forceTurn: true, simulcast: false }, (id, error) => {
      console.log('STREAM PUBLISHED', id, error)
    })
  } */

  startScreenSharing () {
    window.LoggerAPI.addLog('client', new Date(), 'log', 'START_SCREENSHARING', 'Screen sharing starting.')
    if (this.p2p) {
      // eslint-disable-next-line no-undef
      this.localStreamScreen = ErizoV9.Stream({
        screen: true,
        video: true,
        audio: true,
        data: true,
        local: true
      })
    } else {
      // eslint-disable-next-line no-undef
      this.localStreamScreen = Erizo.Stream({
        screen: true,
        video: true,
        data: true,
        local: true
      })
    }

    this.localStreamScreen.addEventListener('bandwidth-alert', (streamEvent) => {
      console.error('THERE IS A BANDWIDTH PROBLEM STREAM SCREEN')
      console.error(streamEvent)
    })
    this.localStreamScreen.addEventListener('access-accepted', (stream) => {
      window.LoggerAPI.addLog('client', new Date(), 'log', 'START_SCREENSHARING_ACCESS_GRANTED', 'Screen sharing access granted.')
      this.localStreamScreen.stream.addEventListener('inactive', (e) => {
        this.stopScreenSharing()
      })
      this.localStreamScreen.stream.addEventListener('ended', (e) => {
        this.stopScreenSharing()
      })
      if (typeof this.localStreamScreen.stream !== 'undefined' && typeof this.localStreamScreen.stream.getVideoTracks() !== 'undefined' && this.localStreamScreen.stream.getVideoTracks().length > 0) {
        this.localStreamScreen.stream.getVideoTracks()[0].addEventListener('ended', (e) => {
          this.stopScreenSharing()
        })
        this.localStreamScreen.stream.getVideoTracks()[0].addEventListener('inactive', (e) => {
          this.stopScreenSharing()
        })
      }
      this.room.publish(this.localStreamScreen, {
        disableIceRestart: true,
        forceTurn: true,
        simulcast: false,
        scheme: 'notify-break-recover'
      }, (id, error) => {
        console.log('Screen shared', id, error)
        this.EventBus.$emit('onStreamAccessGrantedScreen')
      })
    })
    this.localStreamScreen.addEventListener('access-denied', (event) => {
      window.LoggerAPI.addLog('client', new Date(), 'log', 'START_SCREENSHARING_ACCESS_DENIED', 'Screen sharing access denied.')
      this.localStreamScreen = null
      this.EventBus.$emit('onStreamAccessDeniedScreen', {
        'error': event.msg
      })
    })
    this.localStreamScreen.init()
  }

  isAudioMuted = false
  muteAudio () {
    if (this.localStream === null) {
      return
    }
    window.LoggerAPI.addLog('client', new Date(), 'log', 'MUTE_AUDIO', 'Participant muted audio.')
    try {
      this.localStream.muteAudio(true, (result) => {})
    } catch (e) {}

    if (typeof this.localStream.stream !== 'undefined' && this.localStream.stream.getAudioTracks().length > 0) {
      this.localStream.stream.getAudioTracks()[0].enabled = false
    }

    this.isAudioMuted = true
    return this.isAudioMuted
  };

  unmuteAudio () {
    if (this.localStream === null) {
      return
    }
    window.LoggerAPI.addLog('client', new Date(), 'log', 'UNMUTE_AUDIO', 'Participant unmuted audio.')

    try {
      this.localStream.muteAudio(false, (result) => {})
    } catch (e) {}

    if (typeof this.localStream.stream !== 'undefined' && this.localStream.stream.getAudioTracks().length > 0) {
      this.localStream.stream.getAudioTracks()[0].enabled = true
    }

    this.isAudioMuted = false
    return this.isAudioMuted
  };

  getIsAudioMuted () {
    return this.isAudioMuted
  }
  isVideoMuted = false
  muteVideo () {
    if (this.localStream === null) {
      return
    }
    if (this.isVideoEffectRunning) {
      this.effectsSDK.stop()
    }
    window.LoggerAPI.addLog('client', new Date(), 'log', 'MUTE_VIDEO', 'Participant muted audio.')
    try {
      this.localStream.muteVideo(true, (result) => {})
    } catch (e) {}

    if (typeof this.localStream.stream !== 'undefined' && this.localStream.stream.getVideoTracks().length > 0) {
      this.localStream.stream.getVideoTracks()[0].enabled = false
    }

    this.isVideoMuted = true
    return this.isVideoMuted
  };

  unmuteVideo () {
    if (this.localStream === null) {
      return
    }
    if (this.isVideoEffectRunning) {
      this.effectsSDK.run()
    }
    window.LoggerAPI.addLog('client', new Date(), 'log', 'UNMUTE_AUDIO', 'Participant unmuted audio.')

    try {
      this.localStream.muteVideo(false, (result) => {})
    } catch (e) {}

    if (typeof this.localStream.stream !== 'undefined' && this.localStream.stream.getVideoTracks().length > 0) {
      this.localStream.stream.getVideoTracks()[0].enabled = true
    }

    this.isVideoMuted = false
    return this.isVideoMuted
  };

  getIsVideoMuted () {
    return this.isVideoMuted
  }

  hasLocalStream () {
    return this.localStream !== null && typeof this.localStream.stream !== 'undefined' && this.localStream.stream.getVideoTracks().length > 0
  }

  getPeerConnection () {
    if (!this.localStream || !this.localStream.pc) {
      return false
    }
    return this.localStream.pc
  }

  room = null
  setStreamHandlers (roomToken) {
    if (this.p2p) {
      // eslint-disable-next-line no-undef
      this.room = ErizoV9.Room({ token: roomToken })
    } else {
      // eslint-disable-next-line no-undef
      this.room = Erizo.Room({ token: roomToken })
    }
    let firstParticipantJoined = false
    let subscribeToStreams = (streams) => {
      console.log('Show all streams', streams.length, streams)
      for (let index in streams) {
        let stream = streams[index]
        let attributes = stream.getAttributes()
        let userId = null
        if (store.getters.getUser !== false) {
          userId = store.getters.getUser.id
        } else {
          userId = store.getters.getMeeting.participant.id
        }
        console.log('MY USER ID =', userId)
        if (attributes && attributes.user_id && attributes.user_id === userId) {
          console.log('STREAM IS MY OWN, IGNORE IT')
          continue
        }

        if (this.localStream && this.localStream.getID() !== stream.getID() && (!this.localStreamScreen || this.localStreamScreen.getID() !== stream.getID())) {
          window.LoggerAPI.addLog('client', new Date(), 'log', 'SUBSCRIBE_STREAM', 'Participant subscribed stream ' + stream.getID() + '.')
          console.log('Stream really start subscribing')
          this.room.subscribe(stream, {
            disableIceRestart: true,
            audio: true,
            video: true,
            data: true,
            forceTurn: true
            // startVideoBW: 250,
            // defaultVideoBW: 300,
            // maxVideoBW: 5000,
            // maxAudioBW: 5000,
            // limitMaxVideoBW: 5000,
            // limitMaxAudioBW: 5000
          }, function (result, error) {
            console.log('SUBSCRIBETOSTREAMS', result, error)
            if (typeof result === 'undefined') {
              console.log('Error subscribing to stream', error)
            } else {
              console.log('Stream subscribed!', typeof stream, stream)
            }
          })
        }
      }
    }

    this.room.addEventListener('room-connected', (roomEvent) => {
      window.LoggerAPI.addLog('client', new Date(), 'log', 'ROOM_CONNECTED', 'Participant connected to room.')
      subscribeToStreams(roomEvent.streams)
      this.EventBus.$emit('onRoomConnected')

      this.room.publish(this.localStream, {
        disableIceRestart: true,
        forceTurn: true,
        simulcast: false,
        // startVideoBW: 25000,
        // defaultVideoBW: 50000,
        // maxVideoBW: 50000,
        // maxAudioBW: 50000,
        // limitMaxVideoBW: 50000,
        // limitMaxAudioBW: 50000,
        scheme: 'notify-break-recover'
      }, (id, error) => {
        console.log('PUBLISHED STREAM', id, error)
        // Disable this video again if it was muted
        if (this.isAudioMuted) {
          this.muteAudio()
        }
        if (this.isVideoMuted) {
          this.muteVideo()
        }
      })
    })

    this.room.addEventListener('room-error', (roomEvent) => {
      console.log('ROOM ERROR', roomEvent)
      window.LoggerAPI.addLog('client', new Date(), 'log', 'ROOM_ERROR', 'Room error.')
      this.endCall()
    })

    this.room.addEventListener('room-disconnected', (roomEvent) => {
      console.log('ROOM DISCONNECTED', roomEvent)
      window.LoggerAPI.addLog('client', new Date(), 'log', 'ROOM_DISCONNECTED', 'Room disconnected.')
      if (roomEvent.message !== 'expected-disconnection') {
        this.endCall()
      }
      this.room = null
    })

    this.room.addEventListener('stream-unsubscribed', (streamEvent) => {
      console.log('STREAM UNSUBSCRIBED', streamEvent)
      window.LoggerAPI.addLog('client', new Date(), 'log', 'SUBSCRIBE_STREAM', 'Participant unsubscribed stream ' + streamEvent.stream.getID() + '.')
    })

    this.room.addEventListener('stream-subscribed', (streamEvent) => {
      // Should never happen, but ignore subscribes to your own stream
      console.log('STREAM SUBSCRIBED', streamEvent)
      let stream = streamEvent.stream
      console.log(stream)
      if (stream.local === true || (this.localStream && stream.getID() === this.localStream.getID())) {
        console.log(this.localStream.pc.peerConnection)
        console.log('UNSUBSCRIBE MY OWN STREAM')
        this.room.unsubscribe(stream)
        return
      }

      let video = document.createElement('video')
      video.setAttribute('autoplay', 'autoplay')
      video.setAttribute('playsinline', 'playsinline')
      video.classList.add('remote_video')
      video.muted = true
      video.volume = 0
      video.srcObject = stream.stream

      console.log('Created video element')

      this.runningStreams[stream.getID()] = stream.stream

      console.log('Added stream to running streams array')

      if (this.recorder !== null) {
        console.log('Recorder is active, add the video to the recorder')
        this.addVideoToRecorder(stream.stream)

        // Trigger another start_recording message, so that the new user receives it
        this.localStream.sendData({
          'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
          'type': 'start_recording'
        })
      }

      if (!firstParticipantJoined) {
        firstParticipantJoined = true
      }

      if (stream.screen) {
        console.log('The subscribed stream is a ScreenSharing stream')
        video.classList.add('webrtc_screen')
        video.classList.add('webrtc_screen_' + stream.getID())
        video.id = stream.getID()
        if (store.getters.getProduct !== 'direct') {
          video.muted = false
          video.volume = 1
        }

        this.EventBus.$emit('onAddScreen', {
          'type': 'remote',
          'video': video
        })
      } else if (stream.video) {
        console.log('The subscribed stream is a Video stream')
        video.classList.add('webrtc-video')
        video.classList.add('webrtc_video_' + stream.getID())
        video.classList.add('mx-auto')
        video.id = stream.getID()
        /*
        if (store.getters.getProduct === 'direct') {
          video.muted = true
          video.volume = 0
        } else {
          video.muted = false
          video.volume = 1
        }
        */
        video.muted = true
        video.volume = 0

        if (typeof video.sinkId !== 'undefined' && this.activeAudioOutput) {
          this.attachSinkId(video, this.activeAudioOutput)
        }

        console.log('Resulting video tag', video)

        if (this.isVideoEffectRunning) {
          this.reloadEffectsForRemoteParticipants()
        }
        let attrs = stream.getAttributes()
        console.log('Stream attributes', attrs)

        let realName = typeof attrs !== 'undefined' && typeof attrs.real_name !== 'undefined' ? attrs.real_name : null
        if (realName === 'Bezoeker') {
          realName = i18n.t('MeetingRoom.Visitor')
        }
        this.EventBus.$emit('onAddVideo', {
          'stream': stream,
          'type': 'remote',
          'video': video,
          'real_name': realName,
          'id': typeof attrs !== 'undefined' && typeof attrs.user_id !== 'undefined' ? attrs.user_id : null
        })
        console.log('ADD VIDEO FIRED')
      }

      let arrayToStoreChunks = []
      stream.addEventListener('stream-data', (evt) => {
        let data = evt.msg

        let sender = data.sender
        if (sender.indexOf('visitor.') > -1 || sender === 'Bezoeker') {
          sender = i18n.t('MeetingRoom.Visitor')
        }

        // We have a chat message
        if (data.type === 'chat') {
          console.log('emit received message')
          this.EventBus.$emit('onChatMessage', {
            'sender': sender,
            'message': data.content,
            'direction': 'in',
            'datetime': data.datetime,
            'type': 'chat'
          })
        } else if (data.type === 'popup_digid') {
          console.log('emit received popup')
          this.EventBus.$emit('onShowPopupDigid', {
            'sender': sender,
            'type': 'popup_digid'
          })
        } else if (data.type === 'digid_process') {
          console.log('emit received digid')
          this.EventBus.$emit('ongenerateIdentificationProcess', {
            'sender': sender,
            'type': 'digid_process'
          })
        } else if (data.type === 'filepart') {
          if (data.dataChunk.first) {
            arrayToStoreChunks = [] // Reset the array just in case

            let now = new Date()
            this.EventBus.$emit('onFileStarted', {
              'type': 'file',
              'sentByMe': false,
              'id': data.dataChunk.uuid,
              'fileName': data.dataChunk.fileName,
              'fileSize': this.bytesToSize(data.dataChunk.fileSizeReal),
              'progress': 0,
              'sender': sender,
              'datetime': now,
              'direction': 'in'
            })
          }

          this.EventBus.$emit('onFileProgress', {
            'id': data.dataChunk.uuid,
            'progress': data.progress > 100 ? 100 : parseFloat(data.progress).toFixed(2),
            'sender': sender,
            'direction': 'in'
          })

          // Push the file chunk to the chunks array
          arrayToStoreChunks.push(data.dataChunk.message)

          if (data.dataChunk.last) {
            // Join the chunk array so it becomes one file again
            let url = arrayToStoreChunks.join('')
            this.EventBus.$emit('onFileCompleted', {
              'id': data.dataChunk.uuid,
              'fileURL': url,
              'fileName': data.dataChunk.fileName,
              'sender': sender,
              'direction': 'in'
            })

            arrayToStoreChunks = [] // resetting array since we're done
          }
        } else if (data.type === 'end_presentation') {
          this.EventBus.$emit('onPresentationEnded')
        } else if (data.type === 'pdf') {
          this.EventBus.$emit('onPresentationScreenReceived', {
            'sender': sender,
            'pdf': data.content,
            'current_page': data.current_page,
            'total_pages': data.total_pages,
            'signature': data.signature,
            'is_retransmit': data.is_retransmit,
            'host_stream_id': data.host_stream_id,
            'offsetX': data.offsetX,
            'offsetY': data.offsetY,
            'scale': data.scale,
            'drawings': data.drawings,
            'width': data.width,
            'presentation_type': data.presentation_type
          })
        } else if (data.type === 'start_recording') {
          this.EventBus.$emit('onStartRecording', {
            'sender': sender
          })
        } else if (data.type === 'stop_recording') {
          this.EventBus.$emit('onStopRecording', {
            'sender': sender
          })
        } else if (data.type === 'stop_scriptix_recording') {
          this.EventBus.$emit('onStopScriptixRecording', {
            'sender': sender
          })
        } else if (data.type === 'start_scriptix_recording') {
          this.EventBus.$emit('onStartScriptixRecording', {
            'sender': sender
          })
        } else if (data.type === 'orientation_changed') {
          this.EventBus.$emit('onRemoteOrientationChanged', {
            'sender': sender,
            'orientation': data.orientation
          })
        } else if (data.type === 'sync_presentation') {
          this.EventBus.$emit('onPresentationSync', {
            'sender': sender,
            'offsetX': data.offsetX,
            'offsetY': data.offsetY,
            'scale': data.scale,
            'drawings': data.drawings,
            'width': data.width
          })
        } else if (data.type === 'raiseOrLowerHand') {
          this.EventBus.$emit('onRaiseOrLowerHand', {
            'senderId': data.senderId,
            'sender': sender,
            'raiseHand': data.raiseHand
          })
        }
      })
    })

    this.room.addEventListener('stream-attributes-update', (streamEvent) => {
      console.log('STREAM ATTRIBUTES UPDATED', streamEvent)
      // Ignored
    })

    this.room.addEventListener('stream-added', (streamEvent) => {
      console.log('STREAM ADDED', streamEvent, streamEvent.stream.getAttributes())
      let streams = []
      streams.push(streamEvent.stream)
      subscribeToStreams(streams)
    })

    this.room.addEventListener('stream-removed', (streamEvent) => {
      console.log('STREAM REMOVED', streamEvent)
      let stream = streamEvent.stream
      delete this.runningStreams[stream.getID()]
      if (this.recorder !== null) {
        let streams = []
        streams.push(this.localStream.stream)
        let allRemoteStreams = this.getAllStreams()
        Object.keys(allRemoteStreams).forEach(function (stream) {
          streams.push(allRemoteStreams[stream])
        })
        this.recorder.getInternalRecorder().resetVideoStreams(streams)
      }
      if (stream.screen) {
        this.EventBus.$emit('onRemoveScreen', {
          'stream_id': stream.getID()
        })
      } else {
        this.EventBus.$emit('onRemoveVideo', {
          'stream_id': stream.getID()
        })
      }
    })

    this.room.addEventListener('stream-failed', (streamEvent) => {
      console.log('STREAM FAILED', streamEvent)
      window.LoggerAPI.addLog('client', new Date(), 'log', 'FAILED_STREAM', 'Participant encountered a failed stream ' + streamEvent.stream.getID() + '.')
      // var stream = streamEvent.stream
      /* this.EventBus.$emit('onRemoveVideo', {
        'stream_id': stream.getID()
      }) */
      /* if (this.localStream && this.localStream.getID() === stream.getID()) {
        this.room.publish(this.localStream, { forceTurn: true }, () => {
          if (this.isAudioMuted) {
            this.muteAudio()
          }
          if (this.isVideoMuted) {
            this.muteVideo()
          }
        })
      } else if (this.localStreamScreen && this.localStreamScreen.getID() === stream.getID()) {
        this.room.publish(this.localStreamScreen, { forceTurn: true })
      } else {
        console.log('A REMOTE STREAM HAD FAILED, ORIGIN:', streamEvent.origin)
        this.EventBus.$emit('onRemoveVideo', {
          'stream_id': stream.getID()
        })
      } */
    })

    this.room.addEventListener('stream-ended', (streamEvent) => {
      console.log('STREAM ENDED', streamEvent)
      window.LoggerAPI.addLog('client', new Date(), 'log', 'ENDED_STREAM', 'A remote stream ended: ' + streamEvent.stream.getID() + '.')
      var stream = streamEvent.stream
      if (stream.screen) {
        this.EventBus.$emit('onRemoveScreen', {
          'stream_id': stream.getID()
        })
      } else {
        this.EventBus.$emit('onRemoveVideo', {
          'stream_id': stream.getID()
        })
      }
    })

    this.room.addEventListener('connection-failed', function () {
      console.log('CONNECTION FAILED')
    })

    this.room.addEventListener('ice-state-changed', function (event) {
      console.log('ICE CHANGE', event)
    })

    this.room.connect({ singlePC: true })
  }

  openOrJoin (roomIdToJoin, roomToken, product, isTwoWay) {
    this.isCameraSwitching = false
    console.log('OPENORJOIN', roomIdToJoin, roomToken, product, isTwoWay)
    this.roomId = roomIdToJoin
    window.roomId = this.roomId
    // If we have no stream (which is currently only possible using the autologin/1 URL which will be removed later on), request it now
    // TODO: Remove when the entire "if/else" block here when old API is phased out and simply always this.setStreamHandlers(roomToken) here
    if (!this.hasLocalStream()) {
      console.log('No LocalStream found, start it')
      let cameraInputDevice = null
      if (store.getters.getCamSettings.device && store.getters.getCamSettings.device !== '') {
        cameraInputDevice = store.getters.getCamSettings.device
      }
      let audioInputDevice = null
      if (store.getters.getCamSettings.device && store.getters.getCamSettings.audio_input !== '') {
        audioInputDevice = store.getters.getCamSettings.audio_input
      }
      let audioOutputDevice = null
      if (store.getters.getCamSettings.device && store.getters.getCamSettings.audio_output !== '') {
        audioOutputDevice = store.getters.getCamSettings.audio_output
      }

      let audioMuted = false
      if (store.getters.getCamSettings.audio_input_on === false) {
        audioMuted = true
      }

      let videoMuted = false
      if (store.getters.getCamSettings.device_on === false) {
        videoMuted = true
      }

      console.log('AUDIO MUTED?2', audioMuted)
      console.log('VIDEO MUTED?2', videoMuted)

      this.startLocalStreams(product, isTwoWay, () => {
        console.log('LocalStream is started')
        this.setStreamHandlers(roomToken)
      }, cameraInputDevice, audioInputDevice, audioOutputDevice, videoMuted, audioMuted)
    } else {
      console.log('LocalStream found already, setStreamHandlers')
      this.setStreamHandlers(roomToken)
    }
  }

  join (roomId, product, streamBothWays, isAgent) {
    if (typeof streamBothWays === 'undefined') {
      streamBothWays = true
    }
    if (isAgent && !streamBothWays && product === 'weseedo_direct') {
      this.connection.emit('join room', roomId)
    } else {
      if (!this.hasLocalStream()) {
        let cameraInputDevice = null
        if (store.getters.getCamSettings.device && store.getters.getCamSettings.device !== '') {
          cameraInputDevice = store.getters.getCamSettings.device
        }
        let audioInputDevice = null
        if (store.getters.getCamSettings.device && store.getters.getCamSettings.audio_input !== '') {
          audioInputDevice = store.getters.getCamSettings.audio_input
        }
        let audioOutputDevice = null
        if (store.getters.getCamSettings.device && store.getters.getCamSettings.audio_output !== '') {
          audioOutputDevice = store.getters.getCamSettings.audio_output
        }

        let audioMuted = false
        if (store.getters.getCamSettings.audio_input_on === false) {
          audioMuted = true
        }

        let videoMuted = false
        if (store.getters.getCamSettings.device_on === false) {
          videoMuted = true
        }

        console.log(store.getters.getCamSettings)
        console.log('AUDIO MUTED?', audioMuted)
        console.log('VIDEO MUTED?', videoMuted)

        this.startLocalStreams(product, streamBothWays, () => {
          this.initializeRnnoise()
          this.connection.emit('join room', roomId)
        }, cameraInputDevice, audioInputDevice, audioOutputDevice, videoMuted, audioMuted)
      } else {
        this.initializeRnnoise()
        this.connection.emit('join room', roomId)
      }
    }
  }

  decline (roomId) {
    window.LoggerAPI.addLog('client', new Date(), 'log', 'STOP_STREAMS', 'Participant wants to decline the call.')
    this.connection.emit('decline', roomId)
  }

  hold (roomId) {
    window.LoggerAPI.addLog('client', new Date(), 'log', 'HOLD_CALL', 'Participant wants to hold the call.')
    this.connection.emit('hold call', roomId)
  };

  takeOver (meetingId, newAgentId) {
    console.log('takeover', meetingId, newAgentId)
    this.connection.emit('meeting_takeover', meetingId, newAgentId)
  };

  getVideoDimension (element) {
    if (element.nodeName === 'VIDEO') {
      return {
        width: element.videoWidth,
        height: element.videoHeight
      }
    }
    // get the video child from the element
    const video = element.querySelector('video')
    return this.getVideoDimension(video)
  }

  makeScreenshot (element) {
    let canvas
    if (element.nodeName === 'VIDEO') {
      canvas = document.createElement('canvas')
      canvas.width = element.videoWidth
      canvas.height = element.videoHeight
      canvas.getContext('2d').drawImage(element, 0, 0, canvas.width, canvas.height)
    } else {
      let cWidth = 0
      let cHeight = 0
      for (let videoElement of element.querySelectorAll('video')) {
        cWidth += videoElement.clientWidth
        if (videoElement.clientHeight > cHeight) {
          cHeight = videoElement.clientHeight
        }
      }

      // create a canvas with required dimensions
      let lastX = 0
      canvas = document.createElement('canvas')
      canvas.width = cWidth
      canvas.height = cHeight
      let ctx = canvas.getContext('2d')

      for (let videoElement of element.querySelectorAll('video')) {
        ctx.drawImage(videoElement, lastX, 0, videoElement.clientWidth, videoElement.clientHeight)
        lastX += videoElement.clientWidth
      }
    }
    return canvas
  }

  takeScreenshot (element) {
    let canvas = this.makeScreenshot(element)

    let now = new Date().toISOString()
    canvas.toBlob(function (blob) {
      saveAs(blob, now + ' - Screenshot.png')
    }, 'image/png')
  }

  recorder = null
  recordingFileName = ''

  /**
   * Starts the Scriptix recording.
   *
   * @returns {boolean} Returns false if the local stream is not available or the recorder is already started, otherwise returns true.
   */
  startScriptixRecording () {
    if (!this.localStream || this.recorder !== null) {
      return false
    }

    window.LoggerAPI.addLog('client', new Date(), 'log', 'START_SCRIPTIX_RECORDING', 'Scriptix recording started.')
    this.localStream.sendData({
      'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
      'type': 'start_scriptix_recording'
    })

    let today = new Date()
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
    this.recordingFileName = 'WeSeeDo ' + date + ' ' + time + '.webm'
    this.addVideosToRecorder(null, 'audio')
  }
  /**
   * Stops the Scriptix recording and uploads the recording to the server.
   *
   * @param {AxiosInstance} axiosInstance - The Axios instance used for making HTTP requests.
   * @param {Object} progressToast - The progress toast object used to display the upload progress.
   * @param {string} apiKey - The API key used for authentication.
   */
  stopScriptixRecording (axiosInstance, progressToast, apiKey) {
    if (this.recorder) {
      this.recorder.stopRecording(() => {
        window.LoggerAPI.addLog('client', new Date(), 'log', 'STOP_RECORDING', 'Recording stopped.')

        if (this.localStream) {
          this.localStream.sendData({
            'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
            'type': 'stop_scriptix_recording'
          })
        }

        RecordRTC.getSeekableBlob(this.recorder.getBlob(), (seekableBlob) => {
          if (store.getters.companyHasFeature('osaw_transcription')) {
            // upload to OSAW
            axiosInstance.uploadRecordingToOSAW(seekableBlob)
              .then(res => {
                console.log('osaw success uploading')
              })
              .catch(err => {
                console.log(err, 'osaw error uploading')
              })
          } else {
            progressToast.toast('show')
            axiosInstance
              .uploadRecordingToScriptix(seekableBlob, this.recordingFileName, progressToast, apiKey)
              .then(res => {
                console.log('scriptix success uploading')
              })
              .catch(err => {
                console.log(err, 'scriptix error uploading')
              })
          }

          this.recorder = null
        })
      })
    }
  }

  startRecording (element = null) {
    if (!this.localStream || this.recorder !== null) {
      return false
    }

    window.LoggerAPI.addLog('client', new Date(), 'log', 'START_RECORDING', 'Recording started.')
    this.localStream.sendData({
      'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
      'type': 'start_recording'
    })

    let today = new Date()
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
    this.recordingFileName = 'WeSeeDo ' + date + ' ' + time + '.webm'
    this.addVideosToRecorder(element)
  }
  // method that auto starts recording, made only for one way video
  // removed localstream as it made RecordRTC fail
  // Stopping the recording is done through the stopRecording method
  autoStartRecording (element = null) {
    if (this.recorder !== null) {
      return false
    }

    window.LoggerAPI.addLog('client', new Date(), 'log', 'START_RECORDING', 'Recording started.')

    let today = new Date()
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
    this.recordingFileName = 'WeSeeDo ' + date + ' ' + time + '.webm'
    this.addVideosToAutoRecorder(element)
  }

  stopRecording () {
    if (this.recorder) {
      this.recorder.stopRecording(() => {
        window.LoggerAPI.addLog('client', new Date(), 'log', 'STOP_RECORDING', 'Recording stopped.')

        if (this.localStream) {
          this.localStream.sendData({
            'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
            'type': 'stop_recording'
          })
        }

        RecordRTC.getSeekableBlob(this.recorder.getBlob(), (seekableBlob) => {
          RecordRTC.invokeSaveAsDialog(seekableBlob, this.recordingFileName)
          this.recorder = null
        })
      })
    }
  }

  stopAutoRecording (axiosInstance) {
    if (this.recorder) {
      this.recorder.stopRecording(() => {
        window.LoggerAPI.addLog('client', new Date(), 'log', 'STOP_RECORDING', 'Recording stopped.')

        RecordRTC.getSeekableBlob(this.recorder.getBlob(), (seekableBlob) => {
          // Replace this with API Post with the blob
          axiosInstance
            .uploadRecordingToBumicom(seekableBlob, this.recordingFileName)
            .then(res => {
              console.log('Bumicom success uploading')
            })
            .catch(err => {
              console.log(err, 'Bumicom error uploading')
            })
          this.recorder = null
          this.axiosInstance = null
        })
      })
    }
  }

  addVideoToRecorder (stream) {
    if (!this.recorder) {
      return false
    }
    this.recorder.getInternalRecorder().addStreams([stream])
  }

  addVideosToRecorder (element = null, type = 'video') {
    let streams = []
    try {
      streams.push(this.effectsSDK.getStream())
    } catch (e) {
      streams.push(this.localStream.stream)
    }
    let allRemoteStreams = this.getAllStreams()
    Object.keys(allRemoteStreams).forEach(function (stream) {
      streams.push(allRemoteStreams[stream])
    })

    let options = {
      type: 'video',
      mimeType: 'video/webm;codecs=vp8,opus',
      videoBitsPerSecond: 512 * 8 * 1024
    }

    if (store.getters.getProduct === 'direct' && element) {
      const { width, height } = this.getVideoDimension(element)
      options.video = {
        width,
        height
      }
    }
    if (type === 'audio' && store.getters.getProduct !== 'direct') {
      options = { type: 'audio' }
    }

    if (this.recorder === null) {
      const availableStreams = streams.filter(Boolean)

      this.recorder = RecordRTC(availableStreams, options)
      this.recorder.startRecording()
    }
  }

  addVideosToAutoRecorder (element = null) {
    let streams = []
    let allRemoteStreams = this.getAllStreams()
    Object.keys(allRemoteStreams).forEach(function (stream) {
      streams.push(allRemoteStreams[stream])
    })
    let options = {
      type: 'video',
      mimeType: 'video/webm;codecs=vp8,opus',
      videoBitsPerSecond: 512 * 8 * 1024
    }

    if (store.getters.getProduct === 'direct' && element) {
      const { width, height } = this.getVideoDimension(element)
      options.video = {
        width,
        height
      }
    }
    if (this.recorder === null) {
      this.recorder = RecordRTC(streams, options)
      this.recorder.startRecording()
    }
  }
  videoDevices = [];
  audioDevices = []
  outputDevices = [];
  activeVideoDevice = null;
  activeAudioInput = null;
  activeAudioOutput = null;

  isCameraSwitching = false
  isCameraSwitchingNow () {
    return this.isCameraSwitching
  }

  deviceSwitching = false
  isDeviceSwitchingNow () {
    return this.deviceSwitching
  }

  facingMode = 'environment'
  switchCamera () {
    if (this.isCameraSwitching) {
      return
    }
    this.isCameraSwitching = true
    this.activeVideoDevice = null

    let facingMode = null
    if (typeof this.localStream.stream !== 'undefined' && this.localStream.stream.getVideoTracks().length > 0) {
      facingMode = this.localStream.stream.getVideoTracks()[0].getSettings().facingMode
    }
    this.facingMode = facingMode

    if (this.facingMode === 'environment') {
      this.facingMode = 'user'
    } else {
      this.facingMode = 'environment'
    }

    let constraints = this.lastConstraints
    constraints.attributes = this.localStream.getAttributes()
    constraints.video.facingMode = this.facingMode
    if (constraints.video && constraints.video.deviceId) {
      delete constraints.video.deviceId
    }
    this.lastConstraints = constraints

    this.room.unpublish(this.localStream)
    if (this.p2p) {
      // eslint-disable-next-line no-undef
      this.localStream = ErizoV9.Stream(constraints)
    } else {
      // eslint-disable-next-line no-undef
      this.localStream = Erizo.Stream(constraints)
    }
    this.localStream.addEventListener('bandwidth-alert', (streamEvent) => {
      console.error('THERE IS A BANDWIDTH PROBLEM WITH STREAM THAT WAS SWITCHED AT LEAST ONCE')
      console.error(streamEvent)
    })
    this.localStream.addEventListener('access-accepted', async (stream) => {
      if (constraints.audio !== false) {
        this.startSoundMeter()
      }

      await this.room.publish(this.localStream, {
        disableIceRestart: true,
        forceTurn: true,
        simulcast: false,
        scheme: 'notify-break-recover'
      })

      let facingMode = null
      if (!('getCapabilities' in this.localStream.stream.getVideoTracks()[0])) {
        this.activeVideoDevice = this.localStream.stream.getVideoTracks()[0].getSettings().deviceId
        facingMode = this.localStream.stream.getVideoTracks()[0].getSettings().facingMode
      } else {
        this.activeVideoDevice = this.localStream.stream.getVideoTracks()[0].getCapabilities().deviceId
      }

      let video = this.getVideoTag(this.localStream)

      let isMobileDevice = window.DetectRTC.isMobileDevice
      if (!isMobileDevice) {
        isMobileDevice = /iPad/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      }

      if (!facingMode) {
        facingMode = this.facingMode
      }

      this.EventBus.$emit('onStreamAccessGranted', {
        'can_switch_camera': isMobileDevice,
        'stream': this.localStream.stream,
        'video': video,
        'mirrored': facingMode === 'user',
        'video_muted': this.isVideoMuted,
        'audio_muted': this.isAudioMuted
      })

      if (this.isVideoMuted) {
        this.muteVideo()
      }
      if (this.isAudioMuted) {
        this.muteAudio()
      }
      this.isCameraSwitching = false
    })
    this.localStream.init()
  }

  getVideoTag (stream) {
    var video = document.createElement('video')
    video.setAttribute('autoplay', 'autoplay')
    video.setAttribute('playsinline', 'playsinline')
    video.classList.add('webrtc-video')
    // video.setAttribute('style', 'flex-grow: 1; max-height: 100%; max-width: 100%;')
    video.id = stream.getID()
    video.srcObject = stream.stream
    video.muted = true
    video.volume = 0
    return video
  }

  getRealNameFromStream (stream) {
    if (typeof stream === 'undefined') {
      stream = this.localStream
    }
    let realName = typeof stream !== 'undefined' && stream !== null && typeof stream.getAttributes() !== 'undefined' && typeof stream.getAttributes().real_name !== 'undefined' ? stream.getAttributes().real_name : ''
    return realName
  }
  getUserIdFromStream (stream) {
    if (typeof stream === 'undefined') {
      stream = this.localStream
    }
    let userId = typeof stream !== 'undefined' && stream !== null && typeof stream.getAttributes() !== 'undefined' && typeof stream.getAttributes().user_id !== 'undefined' ? stream.getAttributes().user_id : ''
    return userId
  }
  sendChunks = {}
  sendFilePart (dataChunk, chunkId) {
    if (!this.localStream) {
      return
    }

    let event = {
      'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
      'type': 'filepart',
      'dataChunk': dataChunk,
      'fileName': dataChunk.fileName
    }
    if (dataChunk.first) {
      let now = new Date()
      this.sendChunks[dataChunk.uuid] = []
      this.EventBus.$emit('onFileStarted', {
        'type': 'file',
        'sentByMe': true,
        'id': dataChunk.uuid,
        'fileName': dataChunk.fileName,
        'fileSize': this.bytesToSize(dataChunk.fileSizeReal),
        'progress': 0,
        'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
        'datetime': now,
        'direction': 'out'
      })

      event['fileSize'] = this.bytesToSize(dataChunk.fileSize)
      event['progress'] = 0
    }
    this.sendChunks[dataChunk.uuid].push(dataChunk.message)
    if (dataChunk.last) {
      let url = this.sendChunks[dataChunk.uuid].join('')
      this.EventBus.$emit('onFileProgress', {
        'id': dataChunk.uuid,
        'progress': 100,
        'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
        'direction': 'out'
      })

      this.EventBus.$emit('onFileCompleted', {
        'id': dataChunk.uuid,
        'fileURL': url,
        'fileName': dataChunk.fileName,
        'direction': 'out'
      })

      event['progress'] = 100
    }

    if (!dataChunk.first && !dataChunk.last) {
      event['progress'] = ((chunkId * 15000) / dataChunk.fileSize) * 100

      this.EventBus.$emit('onFileProgress', {
        'id': dataChunk.uuid,
        'progress': event['progress'] > 100 ? 100 : parseFloat(event['progress']).toFixed(2),
        'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
        'direction': 'out'
      })
    }
    this.localStream.sendData(event)
  };

  bytesToSize (bytes) {
    let k = 1000
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) {
      return '0 Bytes'
    }
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)), 10)
    return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i]
  }

  download (data, strFileName, strMimeType) {
    let file = this.dataURItoFile(data, strFileName)
    saveAs(file)
  }

  sharePartOfScreen (pdf, currentPage, totalPages, signature, isRetransmit, scale, width, offsetX, offsetY, drawings, presentationType) {
    let data = {
      'host_stream_id': this.localStream.getID(),
      'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
      'type': 'pdf',
      'content': pdf,
      'current_page': currentPage,
      'total_pages': totalPages,
      'is_retransmit': typeof isRetransmit !== 'undefined' ? isRetransmit : false,
      'signature': signature,
      'presentation_type': presentationType
    }

    console.log('test', signature)

    if (isRetransmit) {
      data.scale = scale
      data.offsetX = offsetX
      data.offsetY = offsetY
      data.width = width
      data.drawings = drawings
    }
    this.localStream.sendData(data)
  }

  stopPresentation () {
    if (this.localStream) {
      this.localStream.sendData({
        'sender': typeof this.localStream.getAttributes() !== 'undefined' && typeof this.localStream.getAttributes().real_name !== 'undefined' ? this.localStream.getAttributes().real_name : 'Agent',
        'type': 'end_presentation'
      })
    }
  }

  triggerIdentification () {
    console.log('trigger ID process')
    this.EventBus.$emit('onIdentificationTriggered')
  }

  generateIdentificationProcess () {
    console.log('identification process generated')
    if (this.localStream) {
      let sender = this.getRealNameFromStream(this.localStream)
      this.localStream.sendData({
        'sender': sender,
        'type': 'digid_process'
      })
      this.EventBus.$emit('ongenerateIdentificationProcess', {
        'sender': sender,
        'type': 'digid_process'
      })
    }
  }

  generateRandomString (length) {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let randomString = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      randomString += characters[randomIndex]
    }
    return randomString
  }

  async startIdentificationProcess (currentUrl) {
    let meeting = store.getters.getSignicatConfig
    const { signicatClientID, signicatClientURL } = meeting
    if (!signicatClientID || !signicatClientURL) {
      return
    }
    await Signicat.redirectToAuthorizationEndpoint(currentUrl, signicatClientID, signicatClientURL)
  }

  async exchangeCodeForToken (currentUrl) {
    return Signicat.exchangeCodeForToken(currentUrl)
  }

  toggleDeviceSettingsPane () {
    this.showDeviceSettingsPane = !this.showDeviceSettingsPane
    this.EventBus.$emit('onToggleDeviceSettingsPane', {
      'show': this.showDeviceSettingsPane
    })
  }

  setUploadBumicomRecording (shouldUploadRecording, axiosInstance) {
    this.uploadBumicomRecording = shouldUploadRecording
    this.axiosInstance = axiosInstance
  }

  startMeetingCounter (roomId) {
    this.connection.emit('start-meeting-counter', { roomId })
  }
}

export { Webrtc }
