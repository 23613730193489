<template>
  <div class="custom-speeddial-wrapper">
    <transition name="button-fade">
      <div
        v-if="isVisible"
        class="custom-speeddial-button"
        :class="buttonSizeClass"
        @click="toggleMenu"
      >
        <slot name="button-content" />
      </div>
    </transition>

    <transition name="menu-grow-shrink">
      <div v-if="isMenuVisible" class="custom-speeddial-menu" :style="computedMenuStyle">
        <button
          v-for="(item, index) in items"
          :key="`custom-speeddial-item-${index}`"
          class="custom-speeddial-item"
          :class="itemSizeClass"
          @click="item.command"
          v-tooltip.left="{ value: item.label }"
        >
          <font-awesome-icon :icon="item.icon" aria-hidden="false" :aria-label="item.label" role="presentation" />
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    computedMenuStyle () {
      const itemLength = this.items.length
      let translationAmountY = 0
      const itemHeight = {
        sm: 35,
        md: 40,
        lg: 45
      }[this.size]

      for (let i = 0; i < itemLength; i++) {
        translationAmountY += itemHeight + 10 // includes margin
      }

      return `transform: translateX(-50%) translateY(-${translationAmountY}px);`
    },
    buttonSizeClass () {
      return `speeddial-${this.size}`
    },
    itemSizeClass () {
      const map = { sm: 'xs', md: 'sm', lg: 'md' } // Always one step down
      return `speeddial-${map[this.size]}`
    }
  },
  props: {
    items: {
      required: true
    },
    active: {
      required: false,
      default: false
    },
    size: {
      type: String,
      default: 'md',
      validator: (val) => ['sm', 'md', 'lg'].includes(val)
    }
  },
  data () {
    return {
      isVisible: true, // Controls visibility of the main button
      isMenuVisible: false
    }
  },
  methods: {
    toggleMenu () {
      this.isMenuVisible = !this.isMenuVisible
    }
  },
  mounted () {
    console.log(this.items, 'items')
  }
}
</script>

<style scoped>
.custom-speeddial-wrapper {
  position: relative;
  /* Ensure this is relative so SpeedDial can be positioned correctly */
}

/* Button Animation */
.button-fade-enter-active,
.button-fade-leave-active {
  transition: opacity 0.5s;
}

.button-fade-enter,
.button-fade-leave-to

/* .button-fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

/* Menu Grow and Shrink Animation */
.menu-grow-shrink-enter-active,
.menu-grow-shrink-leave-active {
  transition: height 0.3s ease, opacity 0.3s ease;
}

.menu-grow-shrink-enter,
.menu-grow-shrink-leave-to

/* .menu-grow-shrink-leave-active in <2.1.8 */
  {
  height: 0;
  /* Start with 0 height */
  opacity: 0;
  overflow: hidden;
  /* Hide overflow when collapsed */
}

.menu-grow-shrink-enter-to {
  height: auto;
  /* After entering, height becomes the natural height */
  opacity: 1;
}

/* Main SpeedDial button styles */
.custom-speeddial-button,
.custom-speeddial-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
}

/* Menu styles */
.custom-speeddial-menu {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 999;
  overflow: hidden;
  /* Hide overflow content while transitioning */
  padding: 2px;
}

.custom-speeddial-item i {
  font-size: 16px;
}

/* Main Button Sizes */
.speeddial-sm {
  width: 40px;
  height: 40px;
  font-size: 18px;
}

.speeddial-md {
  width: 60px;
  height: 60px;
  font-size: 24px;
}

.speeddial-lg {
  width: 80px;
  height: 80px;
  font-size: 30px;
}

/* Menu Item Sizes */
.speeddial-xs {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.speeddial-sm {
  width: 40px;
  height: 40px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .custom-speeddial-menu {
    transform: translateX(-50%) translateY(-150px);
    /* Adjust for smaller screens */
  }
}
</style>
