<template>
  <div style="position: relative; height: 300px; border: '2px solid red'">
    <div style="position:fixed; right: 2%; bottom: 5%" v-show="items.length > 0">
        <CustomSpeedDial :items="items" size="sm">
            <template v-slot:button-content>
              <div
                role="button"
                class="d-flex justify-content-center align-items-center rounded-circle font-size-px-25 cursor-pointer"
              >
                <font-awesome-icon
                  :icon="['fas', 'fa-phone']"
                  aria-hidden="false"
                  role="presentation"
                  size="sm"
                />
              </div>
            </template>
          </CustomSpeedDial>
          <form target="_blank" method="post" @submit.prevent="submitForm" style="display: none;" v-if="site">
            <button ref="callAgentButton" class="btn font-weight-normal text-white margin-top-10 w-px-200 h-px-45" :class="{'bg-button': typeof site.overlay_text_color === 'undefined' || site.overlay_text_color === null}" :style="{ 'backgroundColor': typeof site.overlay_text_color !== 'undefined' && site.overlay_text_color !== null ? site.overlay_text_color : '' }" type="submit" v-if="selectedAgent">
              <font-awesome-icon :icon="['fas', 'video']" class="margin-right-10" /> {{ site.overlay_button_text_online }}
            </button>
            <button class="btn font-weight-normal bg-gray margin-top-10 w-px-200 h-px-45" :class="{'text-white': typeof site.overlay_text_color === 'undefined' || site.overlay_text_color === null}" :style="{ 'color': typeof site.overlay_text_color !== 'undefined' && site.overlay_text_color !== null ? site.overlay_text_color : '' }" type="button" disabled v-else>
              {{ site.overlay_button_text_offline }}
            </button>
        </form>
    </div>
    <div v-show="pollingInProgress" class="loading-indicator text-center" style="position: fixed; bottom: 5%; right: 100px; background: white; padding: 8px 12px; border-radius: 6px; box-shadow: 0 0 6px rgba(0,0,0,0.2); z-index:99999;">
      <font-awesome-icon icon="spinner" spin /> {{ $t('Salesforce/WaitingForMeeting') || 'Waiting for agent...' }}
    </div>
  </div>
</template>

<script>
/**
 * WebsiteButton.vue
 *
 * Renders a floating FAB with options for WeSeeDo call and Salesforce integration.
 * Loads inside an iframe and adapts its button visibility based on:
 * - DetectRTC capabilities (webcam/mic)
 * - Agent online/busy status
 * - Browser support
 *
 * Salesforce integration:
 * - POST to get agent ID
 * - Poll for customer URL
 * - Opens meeting when available
 */
import 'iframe-resizer/js/iframeResizer.contentWindow.min'
import CustomSpeedDial from '../direct/FabComponent.vue'
export default {
  name: 'WebsiteButton',
  components: { CustomSpeedDial },
  data () {
    return {
      items: [],
      apiBaseUrl: process.env.VUE_APP_API_HOST,
      token: null,
      site: null,
      selectedAgent: null,
      detectRTC: null,
      isWeSeeDoSupported: null,
      state: this.$localStore.getters.getButtonExpanded ? 'expanded' : 'collapsed',
      windowReference: null,
      pollingCancelled: false,
      pollingInProgress: false,
      salesforceBaseUrl: ''
    }
  },
  methods: {
    async submitForm () {
      let formInfo = await this.$axiosSite.post(this.$axiosSite.routes.button + (this.$root.isBeta === true ? '?beta=true' : ''), {
        a: this.selectedAgent.id,
        s: this.site.id,
        t: this.token
      })
      this.$utils.postMessageToParent('MEETING_CREATED', { meeting_id: formInfo.data.meeting_id, agent_id: this.selectedAgent.id })
      window.open(formInfo.data.url, '_blank')
    },
    toggleState () {
      this.state = this.state === 'expanded' ? 'collapsed' : 'expanded'
      this.$localStore.commit('setButtonExpanded', this.state === 'expanded')
    },
    async refreshUsers () {
      setTimeout(async () => {
        let siteInfo = await this.$axiosSite.get(this.$axiosSite.routes.site + '/' + this.$route.query.s + '?return_images=true')
        if (siteInfo !== null) {
          this.site = siteInfo.data.result
          this.tryBuildItems()
        }
      }, 1000)
    },
    onLostConnection () {
      this.site = null
    },
    onDisconnected () {
      this.site = null
    },
    onReconnected () {
      this.refreshUsers()
    },
    async getAgentId () {
      const res = await fetch(`${this.salesforceBaseUrl}/services/apexrest/weseedo/call-agent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      })
      const data = await res.json()
      return data.id
    },
    async  pollForUrl (agentId, maxAttempts = 12, interval = 5000) {
      // / Poll for the meeting URL https://weseedo.my.salesforce-sites.com/
      const url = `${this.salesforceBaseUrl}/services/apexrest/weseedo/customer-url/${agentId}`
      let attempt = 0

      // eslint-disable-next-line no-unmodified-loop-condition
      while (attempt < maxAttempts && !this.pollingCancelled) {
        attempt++

        try {
          const res = await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          const data = await res.json()

          if (data && data.url) {
            return data.url
          }
        } catch (err) {
          console.warn(`Attempt ${attempt} failed:`, err)
        }

        await new Promise(resolve => setTimeout(resolve, interval))
      }

      if (this.pollingCancelled) {
        throw new Error('Polling cancelled by user.')
      }

      throw new Error('Meeting URL not available after multiple attempts.')
    },

    // Handle the full meeting flow
    async openSalesforceMeeting () {
      this.pollingCancelled = false
      this.pollingInProgress = true

      try {
        const agentId = await this.getAgentId()

        const meetingUrl = await this.pollForUrl(agentId)

        window.open(meetingUrl, '_blank')
      } catch (err) {
        console.error('Salesforce meeting error:', err)
        this.pollingInProgress = false
      }
    },
    tryBuildItems () {
      if (
        this.detectRTC &&
        this.isWeSeeDoSupported !== null
      ) {
        this.buildItems()
      }
    },
    buildItems () {
      const items = []
      // Skip entirely if no webcam/mic or browser doesn't support it
      if (
        !this.detectRTC ||
        !this.detectRTC.hasWebcam ||
        !this.detectRTC.hasMicrophone ||
        !this.isWeSeeDoSupported
      ) {
        // this.items = [] // This causes CustomSpeedDial to hide automatically
        console.log('Skip entirely if no webcam/mic or browser does not support it')
        return
      }

      // Add WeSeeDo button if local agents available
      if (this.site && (this.busyAgents.length > 0 || this.onlineAgents.length > 0)) {
        items.push({
          label: this.site.overlay_button_text_online,
          icon: ['fas', 'fa-video'],
          command: () => {
            this.$nextTick(() => {
              // eslint-disable-next-line no-unused-expressions
              this.$refs.callAgentButton?.click()
            })
          }
        })
      }
      // Add Salesforce button — it requires cam/mic too, but not local agent presence
      items.push({
        label: this.$t('Accessibility/Meeting.Call Salesforce'),
        icon: ['fas', 'fa-headset'],
        disabled: this.pollingInProgress,
        command: () => {
          if (!this.pollingInProgress) this.openSalesforceMeeting()
        }
      })

      this.items = items
    }
  },
  watch: {
    onlineAgents () {
      let agentFound = false
      if (this.selectedAgent !== null) {
        let selectedAgentStillOnline = this.onlineAgents.filter(user => user.id === this.selectedAgent.id)
        if (selectedAgentStillOnline !== null && selectedAgentStillOnline.length > 0) {
          this.selectedAgent = selectedAgentStillOnline[0]
          agentFound = true
        }
      }

      if (!agentFound) {
        let agent = this.onlineAgents[Math.floor(Math.random() * this.onlineAgents.length)]

        if (typeof agent === 'undefined') {
          this.selectedAgent = null
        } else {
          this.selectedAgent = agent
        }
      }
    }
  },
  computed: {
    onlineAgents () {
      if (this.site === null || typeof this.site.users === 'undefined' || this.site.users.length === 0) {
        return []
      }

      return this.site.users.filter(user => (user.role === 'agent' || user.role === 'assistent_video') && user.status === 'online')
    },
    busyAgents () {
      if (this.site === null || typeof this.site.users === 'undefined' || this.site.users.length === 0) {
        return []
      }
      let busyAgents = this.site.users.filter(user => (user.role === 'agent' || user.role === 'assistent_video') && (user.status === 'busy' || user.status === 'request_pending'))
      return busyAgents
    }
  },
  destroyed () {
    this.$webrtc.EventBus.$off('onAgentStatusChanged', this.refreshUsers)
    this.$webrtc.EventBus.$off('onLostConnection', this.onLostConnection)
    this.$webrtc.EventBus.$off('onDisconnected', this.onDisconnected)
    this.$webrtc.EventBus.$off('onReconnected', this.onReconnected)
  },
  async mounted () {
    // Disable existing disconnect handlers, since we need to just hide the button whenever we disconnect here
    this.$webrtc.EventBus.$off('onDisconnected')
    this.$webrtc.EventBus.$on('onAgentStatusChanged', this.refreshUsers)
    this.$webrtc.EventBus.$on('onLostConnection', this.onLostConnection)
    this.$webrtc.EventBus.$on('onDisconnected', this.onDisconnected)
    this.$webrtc.EventBus.$on('onReconnected', this.onReconnected)

    window.DetectRTC.load(() => {
      this.detectRTC = window.DetectRTC
      this.tryBuildItems()
    })

    this.isWeSeeDoSupported = window.isWeSeeDoSupported
    const query = this.$route.query
    this.salesforceBaseUrl = query?.sf_url || ''
    if (this.salesforceBaseUrl.length > 0) {
      this.tryBuildItems()
    }
    console.log(this.$axiosSite.routes.login_site, 'try')
    let authInfo = await this.$axiosSite.post(this.$axiosSite.routes.login_site, {
      site_id: this.$route.query.s
    })
    if (authInfo && typeof authInfo.data !== 'undefined' && typeof authInfo.data.result !== 'undefined') {
      let token = authInfo.data.result.access_token
      this.token = token
      this.$store.commit('setSiteAccessToken', token)
      this.$axiosSite.setToken(token)
      this.refreshUsers()
      this.$webrtc.connect()
    }
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('#weseedo_iframe')?.addEventListener('load', function () {
      this.style.height = '600px' // or use a fixed fallback
    })
  }
}
</script>

<style lang="scss" scoped>
img {
  margin-top: -2px;
  margin-left: -2px;
}
</style>
